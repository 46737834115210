// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-architecture-index-mdx": () => import("./../../../src/pages/architecture/index.mdx" /* webpackChunkName: "component---src-pages-architecture-index-mdx" */),
  "component---src-pages-blog-clinical-records-ingestion-pattern-index-backup-mdx": () => import("./../../../src/pages/blog/clinical-records-ingestion-pattern/index - backup.mdx" /* webpackChunkName: "component---src-pages-blog-clinical-records-ingestion-pattern-index-backup-mdx" */),
  "component---src-pages-blog-clinical-records-ingestion-pattern-index-mdx": () => import("./../../../src/pages/blog/clinical-records-ingestion-pattern/index.mdx" /* webpackChunkName: "component---src-pages-blog-clinical-records-ingestion-pattern-index-mdx" */),
  "component---src-pages-blog-cohort-quality-measure-service-index-mdx": () => import("./../../../src/pages/blog/cohort-quality-measure-service/index.mdx" /* webpackChunkName: "component---src-pages-blog-cohort-quality-measure-service-index-mdx" */),
  "component---src-pages-blog-data-deid-index-mdx": () => import("./../../../src/pages/blog/data-deid/index.mdx" /* webpackChunkName: "component---src-pages-blog-data-deid-index-mdx" */),
  "component---src-pages-blog-getting-started-with-alvearie-index-mdx": () => import("./../../../src/pages/blog/getting-started-with-alvearie/index.mdx" /* webpackChunkName: "component---src-pages-blog-getting-started-with-alvearie-index-mdx" */),
  "component---src-pages-blog-imaging-ingestion-index-mdx": () => import("./../../../src/pages/blog/imaging-ingestion/index.mdx" /* webpackChunkName: "component---src-pages-blog-imaging-ingestion-index-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-blog-smart-keycloak-index-mdx": () => import("./../../../src/pages/blog/smart-keycloak/index.mdx" /* webpackChunkName: "component---src-pages-blog-smart-keycloak-index-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-contributions-index-mdx": () => import("./../../../src/pages/contributions/index.mdx" /* webpackChunkName: "component---src-pages-contributions-index-mdx" */),
  "component---src-pages-contributions-request-slack-access-index-mdx": () => import("./../../../src/pages/contributions/requestSlackAccess/index.mdx" /* webpackChunkName: "component---src-pages-contributions-request-slack-access-index-mdx" */),
  "component---src-pages-fhir-index-mdx": () => import("./../../../src/pages/fhir/index.mdx" /* webpackChunkName: "component---src-pages-fhir-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../../../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-videos-index-mdx": () => import("./../../../src/pages/videos/index.mdx" /* webpackChunkName: "component---src-pages-videos-index-mdx" */)
}

